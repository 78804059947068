<template>
  <Layout>
    <div class="row align-items-center text-center page-headline mb-3">
      <div class="col-md-6 text-md-left">
        <h1 class="display-3">{{ $t("page.company.news") }}</h1>
      </div>

      <div class="col-md-6 text-md-right">
        <router-link :to="`/company/news/create`" class="btn btn-primary"
          ><span class="material-symbols-rounded mr-2"> add </span>
          {{ $t("btn.add-news") }}</router-link
        >
      </div>
    </div>

    <div v-if="loading">
      <div class="text-center">
        <b-spinner variant="primary" />
      </div>
    </div>
    <div v-else>
      <b-alert
        v-if="error"
        variant="danger"
        class="mt-3"
        dismissible
        :show="Boolean(error)"
        >{{ error }}</b-alert
      >
      <template v-else>
        <div class="card">
          <div class="card-body">
            <div class="search-field mb-2">
              <b-form-input
                v-model="search"
                :placeholder="`${$t('btn.search')}...`"
                class="search-field-input"
                @keyup.enter="searchStart"
              />
              <button
                v-if="search.length >= 3"
                class="search-field-btn search-field-btn-reset"
                @click="searchReset"
                :title="$t('btn.reset')"
              >
                <span class="material-symbols-rounded search-field-ico">
                  close
                </span>
              </button>
              <button
                class="search-field-btn"
                @click="searchStart"
                :title="$t('btn.search')"
              >
                <span class="material-symbols-rounded search-field-ico">
                  search
                </span>
              </button>
            </div>

            <div class="row mb-md-2 text-center table-filters">
              <div class="col-sm-12 col-md-auto ml-auto mt-2 mt-md-0">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    <span class="text-nowrap">{{ $t("form.quantity") }}:</span>
                    <b-form-select
                      v-model="per_page"
                      size="sm"
                      :options="pageOptions"
                      class="form-control form-control-sm ml-2"
                    ></b-form-select>
                  </label>
                </div>
              </div>
            </div>

            <div class="table-responsive mb-0">
              <b-table-simple hover caption-top responsive show-empty>
                <b-thead>
                  <b-tr>
                    <b-th class="cell-id">
                      <b-form-checkbox v-model="selectedAll" />
                    </b-th>
                    <b-th>{{ $t("table.photo") }}</b-th>
                    <b-th>{{ $t("table.title") }}</b-th>
                    <b-th class="cell-action">
                      <a
                        v-if="selected?.length"
                        href="#"
                        @click.prevent="confirmDeleteArr()"
                        class="action-delete"
                        :title="$t('btn.remove')"
                        ><span
                          class="material-symbols-rounded fz-28 v-middle mx-1"
                        >
                          delete_forever
                        </span></a
                      >
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <draggable
                    v-model="tableData"
                    handle=".handle"
                    @update="onUpdate"
                    class="draggable-box"
                  >
                    <transition-group tag="div" class="draggable-box-list">
                      <b-tr
                        v-for="(item, i) in tableData"
                        :key="item.id"
                        class="handle"
                      >
                        <b-td>
                          <b-form-checkbox
                            v-model="selected"
                            name="selected"
                            :value="{ id: item.id, name: item.name }"
                          />
                        </b-td>
                        <b-td>
                          <img
                            v-if="item.picture"
                            :src="item.picture"
                            :alt="item.name"
                            class="news-item-thumb"
                          />
                        </b-td>
                        <b-td>{{ item.name }}</b-td>
                        <b-td>
                          <router-link
                            :to="`/company/news/${item.id}`"
                            class="action-edit"
                            :title="$t('btn.edit')"
                            ><span
                              class="material-symbols-rounded fz-24 v-middle mx-1"
                            >
                              edit
                            </span></router-link
                          >
                          <a
                            href="#"
                            @click.prevent="confirmDelete(item)"
                            class="action-delete"
                            :title="$t('btn.remove')"
                            ><span
                              class="material-symbols-rounded fz-28 v-middle mx-1"
                            >
                              delete_forever
                            </span></a
                          >
                        </b-td>
                      </b-tr>
                    </transition-group>
                  </draggable>
                </b-tbody>
                <template #empty>
                  <h4 class="text-center">
                    {{ $t("table.no-data") }}
                  </h4>
                </template>
              </b-table-simple>

              <!-- <b-table
                responsive="sm"
                :items="tableData"
                :fields="tableFields"
                :per-page="per_page"
                :busy.sync="isBusy"
                show-empty
              >
                <template #head(select)="data"></template>
                <template #head(picture)="data">
                  {{ $t("table.photo") }}
                </template>
                <template #head(name)="data">
                  {{ $t("table.title") }}
                </template>
                <template #head(action)="data">
                  v-if="selected?.length" 
                  <a
                    href="#"
                    @click.prevent="confirmDeleteArr()"
                    class="action-delete"
                    :title="$t('btn.remove')"
                    ><span class="material-symbols-rounded fz-28 v-middle mx-1">
                      delete_forever
                    </span></a
                  >
                </template>

                <template #cell(select)="data">1</template>
                <template #cell(picture)="data">
                  <img
                    v-if="data.item.picture"
                    :src="data.item.picture"
                    :alt="data.item.name"
                    class="news-item-thumb"
                  />
                </template>

                <template #cell(action)="data">
                  <router-link
                    :to="`/company/news/${data.item.id}`"
                    class="action-edit"
                    :title="$t('btn.edit')"
                    ><span class="material-symbols-rounded fz-24 v-middle mx-1">
                      edit
                    </span></router-link
                  >
                  <a
                    href="#"
                    @click.prevent="confirmDelete(data.item)"
                    class="action-delete"
                    :title="$t('btn.remove')"
                    ><span class="material-symbols-rounded fz-28 v-middle mx-1">
                      delete_forever
                    </span></a
                  >
                </template>

                <template #empty>
                  <h4 class="text-center">
                    {{ $t("table.no-data") }}
                  </h4>
                </template>
              </b-table> -->
            </div>
            <div class="row mb-3">
              <div class="col-12 text-center">
                <div class="dataTables_paginate paging_simple_numbers">
                  <b-pagination
                    v-model="page"
                    :total-rows="totalRows"
                    :per-page="per_page"
                  ></b-pagination>
                </div>
              </div>
            </div>

            <!-- <div><pre>tableFields: {{ tableFields }}</pre></div> -->
            <!-- <div>
              <pre>tableData: {{ tableData }}</pre>
            </div> -->
            <!-- <div>
              <pre>selected: {{ selected }}</pre>
            </div> -->
          </div>
        </div>
      </template>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useGlobalStore } from "@/store/global";
import Layout from "@/views/layouts/main";
import formNews from "@/components/company/form-news";
import draggable from "vuedraggable";

export default {
  metaInfo() {
    return {
      title: this.$t("app.title") + " | " + this.$t("page.company.news"),
    };
  },
  components: {
    Layout,
    formNews,
    draggable,
  },
  data() {
    return {
      loading: false,
      error: "",
      wait: false,

      search: "",
      // block: "",

      tableData: [],
      totalRows: 0,
      page: 1,
      pageOptions: [25, 50, 100],
      per_page: 25,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      isBusy: false,
      _helper: "",

      selected: [],
      selectedAll: false,

      // tableFields: [
      //   {
      //     key: "select",
      //     // sortable: true,
      //     class: "cell-id",
      //     label: "",
      //   },
      //   {
      //     key: "picture",
      //     // sortable: true,
      //   },
      //   {
      //     key: "name",
      //     // sortable: true,
      //   },
      //   {
      //     key: "action",
      //     label: "",
      //     class: "cell-action",
      //   },
      // ],

      // showModal: false,
      // news: "",
    };
  },
  created() {
    this.loadData();
  },
  computed: {},
  methods: {
    ...mapActions(useGlobalStore, [
      "getNewsList",
      "deleteNews",
      "deleteNewsArr",
      "sortNews",
    ]),
    async setQuery(newQuery, toHistory = false) {
      const query = Object.assign({}, { ...this.$route.query, ...newQuery });

      // delete empty fields
      Object.keys(query).forEach((key) => {
        if (
          query[key] === undefined ||
          query[key] === null ||
          query[key] === ""
        ) {
          delete query[key];
        }
      });

      if (toHistory) {
        await this.$router.push({ query });
      } else {
        await this.$router.replace({ query }).catch((err) => {});
      }
    },
    paramFromQuery() {
      this.search =
        this.$route.query?.search?.length >= 3 ? this.$route.query.search : "";
      // this.block = this.$route.query?.block || "";
      this.per_page = Number(this.$route.query?.per_page) || 25;
      this.page = Number(this.$route.query?.page) || 1;
    },
    async loadData() {
      try {
        this.loading = true;
        this.error = "";

        await this.loadTableData(false);
      } catch (error) {
        console.log("loadData Error: ", error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    async loadTableData(silent = true) {
      if (this.wait) {
        return;
      }

      this.wait = true;
      this.isBusy = true;

      this.paramFromQuery();

      try {
        if (!silent) {
          this.loading = true;
        }
        this.error = "";

        const params = {
          pages: this.per_page,
          page: this.page,
          search: this.search,
        };

        const req = await this.getNewsList(params);
        console.log("loadTableData, req: ", req);

        this.tableData = req.data.data || [];
        this.totalRows = req.data.total;
        this.selected = [];
        this.selectedAll = false;
      } catch (error) {
        console.log("loadTableData Error: ", error);
        this.error = error;

        this.error = this.$t("msg.error");

        if (error.response?.status && error.response?.data?.message) {
          this.error = `[${error.response.status}] ${error.response.data.message}`;
        }
      } finally {
        this.loading = false;
        this.wait = false;
        this.isBusy = false;
      }
    },
    confirmDelete(item) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("modal.delete-confirm-news", { news: item.name }),
          {
            title: this.$t("modal.delete-confirm"),
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: this.$t("btn.yes"),
            cancelTitle: this.$t("btn.cancel"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (sure) => {
          if (sure) {
            await this.deleteNews(item.id);
            this.loadTableData();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    confirmDeleteArr() {
      let news = "";
      this.selected.map((item) => {
        news += `<br />${item.name}`;
      });

      const descr = this.$createElement("div", {
        domProps: {
          innerHTML: this.$t("modal.delete-confirm-news-selected", { news }),
        },
      });

      this.$bvModal
        .msgBoxConfirm(descr, {
          title: this.$t("modal.delete-confirm"),
          size: "md",
          buttonSize: "md",
          okVariant: "danger",
          okTitle: this.$t("btn.yes"),
          cancelTitle: this.$t("btn.cancel"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (sure) => {
          if (sure) {
            await this.deleteNewsArr(this.selected);
            await this.loadTableData();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    searchStart() {
      if (this.search.length >= 3 || this.search === "") {
        this.setQuery({ page: 1, search: this.search }, true);
      }
    },
    searchReset() {
      this.search = "";
      this.setQuery({ page: 1, search: this.search }, true);
    },
    async onUpdate() {
      // sort via drag n drop, save sort
      // console.log("onUpdate, val.from: ", val.from);

      // 1 get maxsortindex
      let maxsortindex = 0;
      this.tableData?.map((item) => {
        if (maxsortindex < parseInt(item.sort)) {
          maxsortindex = parseInt(item.sort);
        }
      });
      // console.log("maxsortindex: ", maxsortindex);

      // 2 map this.tableData to get arr of pair, set sort = [{id, sort}]
      const sorted = this.tableData?.map((item, i) => {
        return {
          id: item.id,
          sort: maxsortindex - i,
        };
      });
      // console.log("sorted: ", sorted);

      // 3 req to save sort
      try {
        await this.sortNews(sorted);
        // this.loadTableData();
      } catch (error) {
        console.log("error.response: ", error.response);
      }
    },
  },
  watch: {
    // watch query like nuxt watchQuery
    "$route.query"(q, old) {
      if (
        ((q.search || old.search) && q.search !== old.search) ||
        ((q.page || old.page) && q.page !== old.page) ||
        ((q.per_page || old.per_page) && q.per_page !== old.per_page)
      ) {
        this.loadTableData();
      }
    },
    page(page) {
      this.setQuery({ page }, true);
    },
    per_page(per_page) {
      this.setQuery({ page: 1, per_page }, true);
    },
    selectedAll(checked) {
      // console.log("onChangeAll... checked: ", checked);

      if (checked) {
        this.selected = this.tableData?.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      } else {
        this.selected = [];
      }
    },
  },
};
</script>
<style scoped lang="scss">
.action-delete {
  color: #f1556c;
}

.draggable-box,
.draggable-box-list {
  display: contents;
}

.news-item-thumb {
  width: auto;
  height: 100%;
  max-height: 80px;
}
</style>
